import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaArrowLeft,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SelectBuilding from "./newDeliveryComponents/SelectBuilding";
import SelectCustomer from "./newDeliveryComponents/SelectCustomer";
import ItemPopup from "./newDeliveryComponents/ItemPopup";
import useGetBuildings from "../../../../hooks/buildings/useGetBuildings";
import useGetCustomers from "../../../../hooks/customers/getCustomers";
import useGetItems from "../../../../hooks/items/getItems";
import toast from "react-hot-toast";
import useAddNewDelivery from "../../../../hooks/delivery/newDelivery";
import { useQueryClient } from "@tanstack/react-query";

const NewDeliveryPage = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [deliveryType, setDeliveryType] = useState("Office");
  const [customer, setCustomer] = useState("");

  const [deliveryStatus, setDeliveryStatus] = useState("pending");
  const [paymentStatus, setPaymentStatus] = useState("unpaid");
  const [showItemPopup, setShowItemPopup] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [building, setBuilding] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const { data, isLoading } = useGetBuildings();
  const qryclient = useQueryClient()
  useEffect(() => {
    if (!isLoading) {
      setBuildings(data?.building || []);
    }
  }, [data, isLoading]);
  const { data: data1, isLoading: isLoading1 } = useGetCustomers();
  useEffect(() => {
    if (!isLoading1) {
      setCustomers(data1?.customersdata || []);
      setFilteredCustomers(data1?.customersdata || []);
    }
  }, [data1, isLoading1]);

  const { data: Itemsdata, isLoading: isLoading2 } = useGetItems(true);
  useEffect(() => {
    if (!isLoading2) {
      setItemsData(Itemsdata?.items || []);
    }
  }, [Itemsdata, isLoading2]);

  const filterCustomers = () => {
    let newCustomers=customers;
    if (deliveryType!=="All") {
      newCustomers = customers.filter(
        (customer) => customer.type === deliveryType.toLowerCase()
      );
      if (building) {
        return newCustomers.filter(
          (customer) => customer.building === building
        );
      }
    }
    return newCustomers;
  };

  useEffect(() => {
    setFilteredCustomers(filterCustomers());
  }, [building, customers, deliveryType]);

  // Debugging logs
  // useEffect(() => {
  //   console.log("Selected Building:", building);
  //   console.log("Filtered Customers:", filteredCustomers);
  // }, [filteredCustomers, building]);
  // const navigate = useNavigate();

  const handleAppendItem = (newItem) => {
    setItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) => item.item_id === newItem.item_id
      );

      if (existingItemIndex !== -1) {
        // If the item already exists, update its quantity
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantity += newItem.quantity;
        return updatedItems;
      } else {
        // If the item does not exist, add it to the array
        return [...prevItems, newItem];
      }
    });
  };

  const handleRemoveItem = (index) => {
    setItems((prevItemsList) => {
      // Create a new array without mutating the original
      const updatedItems = [...prevItemsList];
      updatedItems.splice(index, 1); // Remove the item at the specified index
      return updatedItems;
    });
  };
  const { mutate: adddeliveryfunction } = useAddNewDelivery();
  const handleSubmitDeliver = async () => {
    try {
      const data = {
        items: items.map(({ item_id, quantity }) => ({ item_id, quantity })),
      };
      data.date = date;
      data.deliverystatus = deliveryStatus;
      data.paymentstatus = paymentStatus;
      adddeliveryfunction(
        { data, uid: customer },
        {
          onSuccess: (data) => {
            toast.success(data.message || "Added Successfully");
            setBuilding("");
            setCustomer("");
            setItems([]);
            qryclient.invalidateQueries(["alldeliveryrecords"]);
          },
          onError: (error) => {
            toast.error(error.response.data.message || "error while adding..");
          },
        }
      );
    } catch (error) {
      toast.error("Internal Error Please Refresh page.");
    }
  };
  return (
    <div className="p-4">
      {/* Back Button and Page Title */}

      <div className="flex items-center justify-between mb-3">
        <button
          onClick={() => navigate(-1)}
          className="text-blue-700 flex items-center"
        >
          <FaArrowLeft className="mr-1" /> Back
        </button>
        <h5 className="text-lg font-semibold text-blue-500">New Delivery</h5>
      </div>
      <div className="p-4 bg-slate-200 mb-2 rounded-xl">
        <div className="mb-4">
          {/* {JSON.stringify(items)} */}
          <label className="block font-semibold mb-1">Date</label>
          <div className="flex items-center border shadow-md bg-white rounded p-2">
            <FaCalendarAlt className="mr-2 text-gray-500" />
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full outline-none bg-white"
            />
          </div>
        </div>

        {/* Delivery Type Selection */}
        <div className="mb-4">
          <label className="block font-semibold mb-1">Delivery Type</label>
          <div className=" border bg-white rounded flex justify-between">
            {["All", "Office", "Individual", "Shop"].map((type) => (
              <button
                key={type}
                onClick={() => {
                  setDeliveryType(type);
                  setBuilding("");
                }}
                className={`p-2 w-full ${
                  deliveryType === type ? "bg-blue-500 text-white rounded" : ""
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
      </div>
      {deliveryType && (
        <>
          {deliveryType == "Office" && (
            <>
              <SelectBuilding
                Building={building}
                setBuilding={setBuilding}
                buildings={buildings}
              />
            </>
          )}
          <SelectCustomer
            customer={customer}
            setCustomer={setCustomer}
            customers={filteredCustomers}
          />
          {customer && (
            <>
              {/* Items Summary */}
              <div
                className="mb-4 border rounded-md p-4 shadow-md"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)",
                }}
              >
                {items?.length > 0 && (
                  <h3 className="font-bold text-lg mb-2">Invoice Summary</h3>
                )}
                <div className="mb-4">
                  {/* Summary Card Header */}
                  {items?.length > 0 && (
                    <div className="flex justify-between font-bold p-2 border-b text-gray-600">
                      <div className="w-1/12 text-center">#</div>
                      <div className="w-3/12">Item</div>
                      <div className="w-2/12 text-center">quantity</div>
                      <div className="w-2/12 text-center">Price</div>
                      <div className="w-2/12 text-center">Total</div>
                      <div className="w-2/12 text-center">Actions</div>
                    </div>
                  )}

                  {/* Summary Card Items */}
                  {items?.length > 0 ? (
                    items.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center p-2 border-b"
                      >
                        <div className="w-1/12 text-center">{index + 1}</div>
                        <div className="w-3/12 font-bold">{item.name}</div>
                        <div className="w-2/12 text-center">
                          {item.quantity}
                        </div>
                        <div className="w-2/12 text-center font-bold">
                          {item.price.toFixed(2)}
                        </div>
                        <div className="w-2/12 text-center font-bold">
                          {(item.quantity * item.price).toFixed(2)}
                        </div>

                        {/* Edit and Remove Buttons */}
                        <div className="w-2/12 text-center flex justify-center space-x-2">
                          {/* <button
                            // onClick={() => handleEditItem(index)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            ✏️
                          </button> */}
                          <button
                            onClick={() => handleRemoveItem(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            🗑️
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="p-4 text-center flex flex-col justify-center items-center text-gray-500">
                      <img
                        src="/images/empty-cart.png"
                        className=""
                        width={120}
                        height={120}
                      />
                      Empty Cart..
                    </div>
                  )}

                  {/* Grand Total */}
                  {items?.length > 0 && (
                    <div className="flex justify-end font-bold mt-2 w-12/12">
                      Grand Total :{" "}
                      {items.reduce(
                        (total, item) => total + item.price * item.quantity,
                        0
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <button
                  onClick={() => {
                    setShowItemPopup(true);
                    setIsEditing(false);
                  }}
                  className="w-full border border-blue-500 text-blue-500 bg-white rounded-md py-2 flex items-center justify-center hover:bg-blue-100 transition duration-200"
                >
                  <FaPlus className="mr-2" /> Add Item
                </button>
              </div>
              {/* Delivery and Payment Status */}
              <div className="flex mb-4 space-x-4">
                <select
                  value={deliveryStatus}
                  onChange={(e) => setDeliveryStatus(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="pending">Pending</option>
                  <option value="delivered">Delivered</option>
                </select>
                <select
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="paid">Paid</option>
                  <option value="unpaid">Unpaid</option>
                </select>
              </div>

              {/* Submit Button */}
              <button
                onClick={handleSubmitDeliver}
                className="w-full p-3 bg-blue-500 text-white rounded"
              >
                Submit Delivery
              </button>
            </>
          )}
        </>
      )}
      {/* Item Selection Popup */}
      {showItemPopup && (
        <ItemPopup
          items={itemsData}
          setShowItemPopup={setShowItemPopup}
          handleAppendItem={handleAppendItem}
        />
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default NewDeliveryPage;
