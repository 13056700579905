import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useGetSingleWorker = (uid) => {
  return useQuery({
    queryKey:["getsingleworker",uid],
    queryFn:async()=>{
        const response = await axiosInstance.get(`/outlet/worker/getworker/all/${uid}`);
        if(!response.data)
        {
            throw new Error('error while fetching Workers');
        }
        return response.data;
    }, refetchOnWindowFocus: false,staleTime: 5 * 60 * 1000, retry: 1,

  });
};
export default useGetSingleWorker;