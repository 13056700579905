import { useQuery } from "@tanstack/react-query"
import axiosInstance from "../../utils/axiosInstance"

const useGetDeliveries = (filters = {})=>{
    return useQuery({queryKey:["alldeliveryrecords"],queryFn:async()=>{
        const response = await axiosInstance.get('/outlet/delivery/getdeliverrecord',{params:filters});
        if(!response.data)
        {
            throw new Error("failed to get deliveries")
        }
        return response.data;
    }})
}

export default useGetDeliveries;