import React, { useState } from "react";
import { FaFilter, FaSearch, FaEye, FaPlus, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useGetWorkers from "../../../../hooks/worker/getworkers";
import LoadingPage from "../../../pages/Loading";
import DynamicLoaderFullScreen from "../../../../Ui/full_screen_loader/DynamicLoaderFullScreen";

const WorkersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const navigate = useNavigate();

  let workers = [];
  const { data, isLoading } = useGetWorkers("all");
  workers = data?.workers;
  // Filter workers based on search term and filter type
  const filteredWorkers = workers?.filter(
    (worker) =>
      (filter === "All" || worker.status === filter) &&
      worker?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewWorkerDetails = (id) => {
    navigate(`${id}`);
  };

  const handleNewWorker = () => {
    navigate("new");
  };

  if (isLoading) return <DynamicLoaderFullScreen />;
  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-3">
        <h5 className="text-lg font-semibold">View Workers</h5>
        <button
          onClick={handleNewWorker}
          className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-600"
        >
          <FaPlus className="" /> New Worker
        </button>
      </div>

      {/* Tab Filter Section */}
      <div className="sticky -top-4    bg-white z-10 p-3 mb-4 border-b">
        <div className="flex gap-4 w-full">
          {["All", "active", "inactive"].map((status) => (
            <button
              key={status}
              onClick={() => setFilter(status)}
              className={`flex-1 text-sm text-center p-2 border-b-2  
                            ${
                              filter === status
                                ? "border-blue-500 text-blue-500 font-semibold"
                                : "border-transparent text-gray-600"
                            }
                        `}
            >
              {status}
            </button>
          ))}
        </div>
      </div>
      {/* Search Bar */}
      <div className="flex items-center gap-2 mb-4">
        <FaSearch className="text-gray-600" />
        <input
          type="text"
          placeholder="Search workers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-1 border rounded-md w-full text-sm"
        />
      </div>

      {/* Workers List */}
      <div className="divide-y divide-gray-200">
        {filteredWorkers?.length > 0 ? (
          filteredWorkers?.map((worker) => (
            <div
              key={worker._id}
              className="flex items-start justify-between gap-4 py-3"
            >
              <div className="flex-1 flex items-center">
                <img
                  src={worker.image || "/images/delivery-man.png"}
                  alt={`${worker.name}'s profile`}
                  className="w-12 h-12 rounded-full mr-3"
                />
                <div>
                  <h3 className="font-semibold text-sm">{worker.name}</h3>
                  <p className="text-xs text-gray-500">
                    Email: {worker?.email}
                  </p>
                  <p className="text-xs text-gray-500">
                    Contact: {worker.phone}
                  </p>
                </div>
              </div>
              <div className="flex-none text-right">
                <p className="text-xs text-gray-500">
                  <span
                    className={
                      worker.status === "active"
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    {worker.status}
                  </span>
                </p>
                <div className="flex-none text-right">
                  {/* Aligning button to the right */}
                  <div className="flex justify-end">
                    {" "}
                    {/* Added flex and justify-end to float the button right */}
                    <button
                      className="flex items-center text-blue-500 whitespace-nowrap hover:underline text-sm mt-2"
                      onClick={() => handleViewWorkerDetails(worker._id)}
                    >
                      <FaEye className="mr-1" />
                      <span>View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-4">
            No workers found.
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
export default WorkersPage;
