import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { MdCancel, MdAddShoppingCart } from "react-icons/md";

const ItemPopup = ({ items, setShowItemPopup ,handleAppendItem}) => {
  const [newItem, setNewItem] = useState({
    item_id: "",
    name: "",
    price: 0,
    quantity: 0,
  });

  const handleIncrement = () => {
    setNewItem({ ...newItem, quantity: (newItem.quantity || 0) + 1 });
  };

  const handleDecrement = () => {
    if (newItem.quantity > 0) {
      setNewItem({ ...newItem, quantity: newItem.quantity - 1 });
    }
  };

  const handleAddItem = () => {
    if (!newItem.name || newItem.quantity <= 0) {
      alert("Please select an item and ensure quantity is greater than 0.");
      return;
    }
    // Pass the new item back to the parent
    // console.log("new item",newItem);
    handleAppendItem(newItem);
    setNewItem({ item_id: "", name: "", price: 0, quantity: 0 });
    setShowItemPopup(false);
  };

  const handleItemSelect = (e) => {
    const selectedItem = items.find((item) => item.name === e.target.value);
    if (selectedItem) {
      setNewItem({
        ...newItem,
        item_id: selectedItem?._id,
        name: selectedItem.name,
        price: selectedItem.price,
        quantity: 1, // Set default quantity to 1 when selecting an item
      });
    } else {
      setNewItem({ item_id: "", name: "", price: 0, quantity: 0 });
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex z-50 items-center justify-center">
      <div className="bg-white p-6 rounded-md w-11/12 sm:w-3/4 lg:w-1/2 shadow-lg">
        <h3 className="text-lg font-semibold text-gray-800 mb-6 flex items-center gap-2">
          Select Item
          <MdAddShoppingCart className="text-blue-500 text-xl" />
        </h3>

        {/* Item Selection */}
        <div className="mb-4 flex gap-2">
          <select
            value={newItem.name}
            onChange={handleItemSelect}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select Item</option>
            {items?.map((item) => (
              <option key={item?._id} value={item.name}>
                {`${item.name} - $${item.price}`}
              </option>
            ))}
          </select>
          <div className="flex items-center border rounded-md">
            {/* Decrement Button */}
            <button
              onClick={handleDecrement}
              className={`px-4 py-2 bg-red-500 hover:bg-red-600 rounded h-full text-white flex items-center justify-center ${
                newItem.quantity > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50"
              }`}
              disabled={newItem.quantity <= 0}
            >
              <FaMinus />
            </button>
            {/* Quantity Display */}
            <div className="px-4 font-extrabold text-xl text-center font-semibold">
              {newItem.quantity || 0}
            </div>
            {/* Increment Button */}
            <button
              onClick={handleIncrement}
              className="px-4 py-2 h-full rounded bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center"
            >
              <FaPlus />
            </button>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          {/* Cancel Button */}
          <button
            onClick={() => {
              setNewItem({ item_id: "", name: "", price: 0, quantity: 0 });
              setShowItemPopup(false);
            }}
            className="flex items-center gap-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            <MdCancel className="text-lg" />
            Cancel
          </button>
          {/* Add Item Button */}
          <button
            onClick={handleAddItem}
            className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Add Item
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemPopup;
