import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <img src="/images/404.gif" width={180} height={180} className='rounded-lg bg-blend-multiply'/>
      <p className="mt-4 text-xl text-gray-600">Page Not Found</p>
      <Link to="/" className="mt-6 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300">
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
