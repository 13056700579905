import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useGetDeliveryDetails = (deliveryid) => {
  return useQuery({
    queryKey: ["DeliveryDetails", deliveryid],
    queryFn: async () => {
        const response = await axiosInstance.get(`/outlet/delivery/getsingledeliverydetails?deliveryid=${deliveryid}`);
        if(!response.data)
        {
            throw new Error('error while fetching detail of delivery')
        }
        return response.data;
    },
  });
};
export default useGetDeliveryDetails;