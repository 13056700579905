// src/hooks/useLogin.js
import { useMutation } from '@tanstack/react-query';
import axiosInstance from '../utils/axiosInstance';


const useLogin = () => {
  const mutation=  useMutation({
    mutationFn: async ({ email, password, type }) => {
      const response = await axiosInstance.post('/auth/signin', {
        email,
        password,
        type
      });

      if (!response.data) {
        throw new Error('Login failed. No data received.');
      }
 
      return response.data;
    },
  });
  return mutation;
};

export default useLogin;
