import { useQuery } from "@tanstack/react-query"
import axiosInstance from "../../utils/axiosInstance"

const useCustomerStats = (customerId)=>{
    return useQuery({queryKey:["customerStats",customerId],queryFn:async()=>{
        const response = await axiosInstance.get(`/outlet/customer/getstats?customer_id=${customerId}`);
        if(!response.data) throw new Error ("error while fetching data")
        return response.data;
    }})
}
export default useCustomerStats;