import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  FaUserCircle,
  FaUsers,
  FaFileInvoice,
  FaDatabase,
  FaChartLine,
  FaCog,
  FaStar,
  FaHeadset,
  FaSignOutAlt,
} from "react-icons/fa"; // Import icons from react-icons
import { IoTimeSharp } from "react-icons/io5";
import toast from "react-hot-toast";
import UserContext from "../../../../context/UserContext";

const MorePage = () => {
  const {user} = useContext(UserContext);
  const [showSpark, setShowSpark] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  // Set a timeout to hide the spark after it completes the animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpark(false);
    }, 3000); // Duration of the animation
    return () => clearTimeout(timer);
  }, []);
  const handleLogout = () => {
    setShowLogoutModal(true);
  };
const navigate = useNavigate();
  const confirmLogout = () => {
    // Perform logout action here
    localStorage.removeItem("token")
    console.log("Logged out");
    toast.success("Logging Out");
    navigate("/")    
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  return (
    <div className="p-4">
      {/* Profile Card */}
      <Link to="account">
        {" "}
        <div className="flex items-center justify-between bg-white p-4 rounded-md shadow-lg mb-4">
          <div className="flex items-center">
            <img src="/images/founder.jpg" width={60} className=" ml-2 mr-2 object-cover rounded-full"/>
            {/* Profile icon */}
            <div>
              <h3 className="text-lg font-semibold">{user?.outlet_name}</h3>
              <p className="text-sm text-gray-500">{user?.email}</p>
            </div>
          </div>
          <Link to="/account" className="text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </Link>
        </div>
      </Link>
      {/* Horizontal Divider */}
      <hr className="mb-4" />

      {/* More Options */}
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 mb-4">
        {/* Manage Workers Button */}
        <Link
          to="inventory"
          className="flex items-center justify-between p-4 border rounded-md"
        >
          <div className="flex items-center">
            <IoTimeSharp className="text-blue-500 mr-2" />
            <span>Inventory</span>
          </div>
        </Link>

        {/* Invoice Button */}
        <Link
          to="invoice"
          className="flex items-center justify-between p-4 border rounded-md"
        >
          <div className="flex items-center">
            <FaFileInvoice className="text-blue-500 mr-2" />
            <span>Invoice</span>
          </div>
        </Link>

        {/* Backup Button */}
        <Link
          to="backup"
          className="flex items-center justify-between p-4 border rounded-md"
        >
          <div className="flex items-center">
            <FaDatabase className="text-blue-500 mr-2" />
            <span>Backup</span>
          </div>
        </Link>

        {/* Reports Button */}
        <Link
          to="reports"
          className="flex items-center justify-between p-4 border rounded-md"
        >
          <div className="flex items-center">
            <FaChartLine className="text-blue-500 mr-2" />
            <span>Reports</span>
          </div>
        </Link>
      </div>

      {/* Additional Menus */}
      {[
        { name: "Settings", icon: FaCog, path: "settings" },
        { name: "About Us", icon: FaStar, path: "support" },
        { name: "Support", icon: FaHeadset, path: "support" },
      ].map((menu, index) => (
        <Link to={menu.path}>
          <div
            key={index}
            className="flex items-center justify-between py-4 border-t"
          >
            <div className="flex items-center">
              <menu.icon
                className={`mr-2 ${
                  menu.name === "Logout" ? "text-red-500" : "text-green-500"
                }`}
              />
              <span
                className={`${menu.name === "Logout" ? "text-red-500" : ""}`}
              >
                {menu.name}
              </span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </div>
        </Link>
      ))}
      <div
        onClick={handleLogout}
        className="flex items-center justify-between py-4 border-t cursor-pointer"
      >
        <div className="flex items-center">
          <FaSignOutAlt className="text-red-500 mr-2" />
          <span className="text-red-500">Logout</span>
        </div>
      </div>
      {showLogoutModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-50">
          <div className="w-full sm:w-3/4 md:w-1/2 bg-white rounded-t-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Confirm Logout</h3>
            <p className="text-sm text-gray-500 mb-6">
              Are you sure you want to log out?
            </p>
            <div className="flex justify-between">
              <button
                onClick={cancelLogout}
                className="w-1/2 mr-2 p-2 border border-gray-300 rounded-md text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={confirmLogout}
                className="w-1/2 ml-2 p-2 bg-red-500 text-white rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Copyright Banner */}
      <div
  className="relative mt-6 p-4 rounded-lg shadow-lg flex items-center justify-center text-white overflow-hidden"
  style={{
    background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
    backdropFilter: "blur(10px)",
  }}
>
  {/* Left Side Image */}
  <div className="flex-shrink-0">
    <img
      src="/images/buildogic.jpg"
      alt="Buildogic Logo"
      className="w-16 h-16 mix-blend-lighten"
    />
  </div>

  {/* Center Text Container */}
  <div className="text-center flex flex-col items-center mr-8">
    <p className="mb-1 font-semibold">© 2024 Buildogic</p>
    <p className="text-sm">Owned by Buildogic</p>
    <a
      href="https://www.buildogic.com"
      className="text-blue-200 hover:text-blue-400"
      target="_blank"
      rel="noopener noreferrer"
    >
      www.buildogic.com
    </a>
  </div>
</div>
  <br/><br/><br/> 
    </div>
  );
};

export default MorePage;

/* CSS for spark animation */
<style jsx>{`
  .spark-animation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .spark {
    position: absolute;
    width: 12px;
    height: 12px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.5);
    animation: sparkMove 3s linear forwards;
  }

  @keyframes sparkMove {
    0% {
      top: 50%;
      left: -12px;
      transform: translate(-50%, -50%);
    }
    25% {
      top: -12px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    50% {
      top: 50%;
      left: calc(100% + 12px);
      transform: translate(-50%, -50%);
    }
    75% {
      top: calc(100% + 12px);
      left: 50%;
      transform: translate(-50%, -50%);
    }
    100% {
      top: 50%;
      left: -12px;
      transform: translate(-50%, -50%);
      opacity: 0; /* Fades out at the end */
    }
  }
`}</style>;
