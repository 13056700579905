import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  FaEdit,
  FaList,
  FaMoneyBill,
  FaFileInvoice,
  FaUser,
  FaArrowLeft,
  FaCheckCircle,
  FaExclamationTriangle,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import OrdersTab from "./OrdersTab";
import BillingDetails from "./BillingDetails";
import PaymentHistory from "./PaymetsTab";
import CustomerViewCard from "./ProfileCard";
import useGetSingleCustomer from "../../../../hooks/customers/getSingleCustomer";
import LoadingPage from "../../../pages/Loading";
import DynamicLoaderFullScreen from "../../../../Ui/full_screen_loader/DynamicLoaderFullScreen";
import useCustomerStats from "../../../../hooks/customers/getCustomerStats";

const dummyOrders = [
  {
    orderId: "12345",
    date: "2024-10-01",
    items: [
      { name: "Tea", size: "200 ml", price: 50, quantity: 2 },
      { name: "Coffee", size: "250 ml", price: 70, quantity: 1 },
    ],
  },
  {
    orderId: "67890",
    date: "2024-10-02",
    items: [
      { name: "Sandwich", size: "Regular", price: 150, quantity: 3 },
      { name: "Cookies", size: "Standard", price: 30, quantity: 5 },
    ],
  },
  {
    orderId: "54321",
    date: "2024-10-03",
    items: [
      { name: "Pastry", size: "Small", price: 80, quantity: 2 },
      { name: "Muffin", size: "Medium", price: 50, quantity: 4 },
    ],
  },
];

const dummyPayments = [
  {
    id: 1,
    date: "2024-10-01",
    amount: 5000,
    status: "Completed",
  },
  {
    id: 2,
    date: "2024-10-05",
    amount: 3000,
    status: "Pending",
  },
  {
    id: 3,
    date: "2024-10-10",
    amount: 2000,
    status: "Completed",
  },
];

const dummyBilling = [
  {
    id: 1,
    month: "October 2024",
    totalAmount: 15000,
    status: "Pending",
  },
  {
    id: 2,
    month: "September 2024",
    totalAmount: 12000,
    status: "Completed",
  },
];

const CustomerProfile = () => {
  const { customerId } = useParams();
  const { data ,isLoading,error} = useGetSingleCustomer(customerId);
  const customer = data?.customer;
  const {data:statsdata,isLoading:statsIsLoading,error:statsError} = useCustomerStats(customerId);
  const customerStats = statsdata?.data;
  
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("overview");
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleTabClick = (tab) => setActiveTab(tab);
  const handleFilterChange = (event) => setFilter(event.target.value);
  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const filteredOrders = dummyOrders.filter((order) => {
    return (
      order.orderId.includes(searchTerm) ||
      order.items.some((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });

  if (isLoading) {
    return (
     <DynamicLoaderFullScreen/>
    );
  }
  
  if (error) {
    return <div>Error fetching customer data.</div>; // Handle errors gracefully
  }

  return (
    <div className="p-4">
      {/* Go Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-blue-500 mb-2 hover:underline"
      >
        <FaArrowLeft className="mr-1" /> Go Back
      </button>

      {/* Profile Card */}
      <CustomerViewCard customer={customer} />

      {/* Horizontal Menu */}
      <div className="flex gap-4 overflow-x-auto pb-2 border-b mb-4">
        <button
          onClick={() => handleTabClick("overview")}
          className={`flex-1 p-2 text-sm ${
            activeTab === "overview"
              ? "text-blue-500 border-b-2 border-blue-500"
              : "text-gray-600"
          }`}
        >
          <FaList className="inline-block mr-1" /> Overview
        </button>
        <button
          onClick={() => handleTabClick("orders")}
          className={`flex-1 p-2 text-sm ${
            activeTab === "orders"
              ? "text-blue-500 border-b-2 border-blue-500"
              : "text-gray-600"
          }`}
        >
          <FaList className="inline-block mr-1" /> Orders
        </button>
        <button
          onClick={() => handleTabClick("payments")}
          className={`flex-1 p-2 text-sm ${
            activeTab === "payments"
              ? "text-blue-500 border-b-2 border-blue-500"
              : "text-gray-600"
          }`}
        >
          <FaMoneyBill className="inline-block mr-1" /> Payments
        </button>
        <button
          onClick={() => handleTabClick("billing")}
          className={`flex-1 p-2 text-sm ${
            activeTab === "billing"
              ? "text-blue-500 border-b-2 border-blue-500"
              : "text-gray-600"
          }`}
        >
          <FaFileInvoice className="inline-block mr-1" /> Billing
        </button>
      </div>

      {/* Content Section */}
      <div>
        {activeTab === "overview" && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Overview Cards */}
            <div className="bg-green-100 p-4 rounded-lg shadow-md">
              <h4 className="font-semibold text-green-700">Total Orders</h4>
              <p className="text-xl font-bold text-green-700">{customerStats?customerStats.Orders.delivered + customerStats.Orders.undelivered:'-'}</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg shadow-md">
              <h4 className="font-semibold text-yellow-700">
                Order details
              </h4>
              <p className="text-md font-bold text-yellow-700"><span className="text-sm">Delivered:</span>{customerStats?customerStats.Orders.delivered :'-'} / <span className="text-sm">Pending:</span>{customerStats?customerStats.Orders.undelivered :'-'}</p>
            </div>
            <div className="bg-blue-100 p-4 rounded-lg shadow-md">
              <h4 className="font-semibold text-blue-700">Total Billing</h4>
              <p className="text-xl font-bold text-blue-700">₹{customerStats?customerStats.Payments.completed + customerStats.Payments.pending:'-'}</p>
            </div>
            <div className="bg-purple-100 p-4 rounded-lg shadow-md">
              <h4 className="font-semibold text-purple-700">
                Payments Details
              </h4>
              <p className="text-md font-bold text-purple-700"><span className="text-sm">Paid:</span>₹{customerStats?customerStats.Payments.completed :'-'} / <span className="text-sm">UnPaid:</span>₹{customerStats?customerStats.Payments.pending :'-'}</p>
            </div>
          </div>
        )}
        {activeTab === "orders" && <OrdersTab />}
        {activeTab === "payments" && <PaymentHistory />}
        {activeTab === "billing" && <BillingDetails />}
      </div>
    </div>
  );
};

export default CustomerProfile;
