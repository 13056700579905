import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from "./components/dashboard/Dashboard";
import SplashScreen from "./components/SplashScreen";
import Home from "./components/dashboard/Home";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Customer from "./components/dashboard/Customer";
import NotFound from "./components/pages/404";
import ViewCustomers from "./components/dashboard/components/customers/ViewCustomers";
import CustomerProfile from "./components/dashboard/components/customers/CustomerProfile";
import InventoryPage from "./components/dashboard/components/inventory/InventoryPage";
import OrdersPage from "./components/dashboard/components/orders/OrdersPage";
import ViewOrderDetails from "./components/dashboard/components/orders/ViewOrder";
import MorePage from "./components/dashboard/components/more/MorePage";
import WorkersPage from "./components/dashboard/components/workers/WorkersView";
import MoreRoute from "./components/dashboard/components/more/more";
import UserProfile from "./components/dashboard/components/more/UserProfile";
import SupportAboutUs from "./components/dashboard/components/more/SupportAndAboutus";
import SettingsPage from "./components/dashboard/components/more/Settings";
import BackupPage from "./components/dashboard/components/more/BackupPage";
import NewDeliveryPage from "./components/dashboard/components/orders/NewDeliveryPage";
import LoginScreen from "./components/NewLogin";
import NewWorker from "./components/dashboard/components/workers/NewWorker";
import WorkerProfile from "./components/dashboard/components/workers/WorkerProfile";
import InvoiceComponent from "./components/dashboard/components/more/Invoice";
import ReportsComponent from "./components/dashboard/components/more/Reportspage";
import ViewItemComponent from "./components/dashboard/components/inventory/ViewItem";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Register from "./components/Register";
import Invoice from "./components/dashboard/components/orders/Invoice";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      cacheTime: 600000,
      staleTime: 300000,
    }
  },
});
function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/" element={<SplashScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute allowedRoles={["6704cb183ab1cdcd71aa8f14"]}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Home />} />
                <Route path="customer" element={<ViewCustomers />} />
                <Route
                  path="customer/:customerId"
                  element={<CustomerProfile />}
                />
                <Route path="workers" element={<WorkersPage />} />
                <Route path="workers/new" element={<NewWorker />} />
                <Route path="workers/:uid" element={<WorkerProfile />} />
                <Route path="orders" element={<OrdersPage />} />
                <Route path="orders/:orderid" element={<ViewOrderDetails />} />
                <Route path="orders/invoice" element={<Invoice />} />
                <Route
                  path="orders/newdelivery"
                  element={<NewDeliveryPage />}
                />
                <Route path="more" element={<MoreRoute />}>
                  <Route index element={<MorePage />} />
                  <Route path="inventory" element={<InventoryPage />} />
                  <Route
                    path="inventory/:itemid"
                    element={<ViewItemComponent />}
                  />
                  <Route path="account" element={<UserProfile />} />
                  <Route path="support" element={<SupportAboutUs />} />
                  <Route path="backup" element={<BackupPage />} />
                  <Route path="invoice" element={<InvoiceComponent />} />
                  <Route path="reports" element={<ReportsComponent />} />
                  <Route path="settings" element={<SettingsPage />} />
                </Route>
                {/* <Route path="my-profile" element={<MyProfile />} />
          <Route path="settings" element={<Settings />} /> */}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      </QueryClientProvider>
    </>
  );
}

export default App;
{
  /* <ProtectedRoute allowedRoles={['6704cb183ab1cdcd71aa8f14']}></ProtectedRoute> */
}
