// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://backend-chaibook.vercel.app/api/v1', // Replace with your API base URL
  // baseURL: 'http://localhost:1208/api/v1',
  timeout: 10000, // Request timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

// You can add interceptors here if needed
// Example: Add a request interceptor to include the token
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  
  
  if (token) {
    config.headers.auth_token = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
