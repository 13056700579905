import React, { useState } from "react";
import { FaFilter, FaSearch, FaEye, FaPlus, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AddNewItem from "./AddNewItem";
import { Link } from "react-router-dom";
import { useGetItems } from "../../../../hooks/items/getItems";
import useUpdateItem from "../../../../hooks/items/updateItem";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import DynamicLoaderFullScreen from "../../../../Ui/full_screen_loader/DynamicLoaderFullScreen";

const InventoryPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [isModalOpen, setModalOpen] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const navigate = useNavigate();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { data, error, isLoading } = useGetItems("all");
  const { mutate: updateItem } = useUpdateItem();
  const qryclient = useQueryClient();
  React.useEffect(() => {
    if (data?.items) {
      setItemsData(data.items);
    }
  }, [data]);

  if (isLoading) {
    return <DynamicLoaderFullScreen/>
  }
  if (error) {
    return <span>Error loading items</span>;
  }

  const handleViewItemDetails = (id) => {
    navigate(`${id}`);
  };

  const toggleStatus = (item) => {
    //set the toggle button action before api call for enhance user experince
    setItemsData((prevItems) =>
      prevItems.map((itemm) =>
        itemm._id === item._id ? { ...itemm, status: !itemm.status } : itemm
      )
    );
    updateItem(
      {
        id: item._id,
        data: {
          status: !item.status,
        },
      },
      {
        onSuccess: (data) => {
          toast.success("updated");
          qryclient.invalidateQueries(["getItems"]);
        },
        onError: (err) => {
          console.log(err);
          setItemsData((prevItems) =>
            prevItems.map((itemm) =>
              itemm._id === item._id
                ? { ...itemm, status: !itemm.status }
                : itemm
            )
          );
          toast.error("failed to update");
        },
      }
    );
  };

  const filteredItems = itemsData.filter(
    (item) =>
      (filter === "All" || item.category === filter) &&
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="p-4 -m-4 h-screen">
        {<AddNewItem isOpen={isModalOpen} onClose={closeModal} />}
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => navigate(-1)}
            className="text-blue-700 flex items-center"
          >
            <FaArrowLeft className="mr-1" /> Back
          </button>
          <h5 className="text-lg font-semibold">Inventory Management</h5>
          <button
            onClick={openModal}
            className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-600"
          >
            <FaPlus />
            Add Item
          </button>
        </div>

        <div className="sticky -top-4 z-10 p-2 px-6 mb-6 -m-2 	bg-white">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
            {/* Dropdown for Item Type Filter
            <div className="flex items-center gap-2 w-full sm:w-auto">
                <FaFilter className="text-gray-600" />
                <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="p-1 border rounded-md w-full sm:w-auto text-sm"
                >
                <option value="All">All Categories</option>
                <option value="Tea">Tea</option>
                <option value="Coffee">Coffee</option>
                </select>
            </div> */}

            {/* Search Bar */}
            <div className="flex items-center gap-2 w-full sm:w-1/2">
              <FaSearch className="text-gray-600" />
              <input
                type="text"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="p-1 border rounded-md w-full text-sm"
              />
            </div>
          </div>
        </div>

        {/* Inventory List */}
        <div className=" -m-4 p-4 h-full">
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <div
                key={item._id}
                className="flex  border border-gray-100 p-6 text-gray-600 shadow-sm p-4 mt-2 rounded-xl items-start gap-3 py-3 "
              >
                <div className="flex-shrink-0 w-20 h-20 border rounded-md overflow-hidden">
                  {item.image ? (
                    <img
                      src={item.image}
                      alt={item.name}
                      className="object-cover w-full h-full  rounded-md"
                    />
                  ) : (
                    <span className="text-lg text-gray-700 flex items-center justify-center h-full">
                      {item.name.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>
                <div className="flex-1">
                  <h3 className="font-semibold text-sm">{item.name}</h3>
                  <p className="text-xs text-gray-500">
                    {item.size} ({item.unit})
                  </p>
                  <p className="text-xs text-gray-500">
                    Stock:{" "}
                    <span
                      className={
                        item.stock === 0 ? "text-red-500" : "text-black"
                      }
                    >
                      {item.stock === 0 ? "Out of stock" : "Available"}
                    </span>
                  </p>
                  <p className="text-xs text-gray-500">Price: ₹{item.price}</p>
                </div>
                <div className="flex flex-col gap-3 ml-auto items-center">
                  {/* <button
                  className="text-blue-500 hover:underline text-sm flex items-center gap-1"
                  onClick={() => handleViewItemDetails(item._id)}
                >
                  <FaEye />
                  View
                </button> */}
                  <div className="flex items-center gap-1">
                    <label className="flex cursor-pointer select-none items-center">
                      <div className="relative">
                        <input
                          type="checkbox"
                          checked={item.status}
                          onChange={() => toggleStatus(item)}
                          className="sr-only"
                        />
                        <div
                          className={`block h-5 w-10 rounded-full ${
                            item.status ? "bg-green-500" : "bg-gray-300"
                          }`}
                        ></div>
                        <div
                          className={`dot absolute left-0.5 top-0.5 h-4 w-4 rounded-full bg-white transition ${
                            item.status ? "translate-x-5" : ""
                          }`}
                        ></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500 py-4">
              No items found.
            </div>
          )}
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default InventoryPage;
