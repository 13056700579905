const SelectCustomer = ({customer,setCustomer,customers})=>{
    return (<>
    <div className="mb-4">
            <label className="block font-semibold mb-1">Select Customer</label>
            <select
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value={null}>Select Customer</option>
              {
                customers?.map((customer)=>(

                  <option value={customer._id}>{customer?.name}</option>
                ))
              }
            </select>
          </div>
    </>)
}
export default SelectCustomer;