import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useUpdateUserProfile = () => {
  return useMutation({
    mutationFn: async (formdata) => {
      const response = await axiosInstance.patch(
        "/outlet/profile/updatebasicdetails",
        formdata
      );
      if (!response.data) {
        throw new Error("error while updating profile");
      }
      return response.data;
    },
  });
};

export default useUpdateUserProfile;
