import React, { useContext, useState } from "react";
import {
  FaArrowLeft,
  FaUserEdit,
  FaPhone,
  FaEnvelope,
  FaPencilAlt,
  FaHome,
  FaArrowRight,
} from "react-icons/fa";
import { MdAccountBox } from "react-icons/md";
import UserContext from "../../../../context/UserContext";
import useUpdateUserProfile from "../../../../hooks/profile/profileUpdate";
import toast from "react-hot-toast";

const UserProfile = () => {
  const { user, setUser } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(user);

  // Handle back button click
  const handleBack = () => {
    window.history.back();
  };
  const { mutate: updateProfile } = useUpdateUserProfile();
  const handleUpdate = async () => {
    const formdata = {};

    // Compare updatedUser with user and add only changed fields to formdata
    if (updatedUser.outlet_name !== user.outlet_name) {
      formdata.OutletName = updatedUser.outlet_name;
    }
    if (updatedUser.firstname !== user.firstname) {
      formdata.FirstName = updatedUser.firstname;
    }
    if (updatedUser.lastname !== user.lastname) {
      formdata.LastName = updatedUser.lastname;
    }
    if (updatedUser.email !== user.email) {
      formdata.Email = updatedUser.email;
    }
    if (updatedUser.phone !== user.phone) {
      formdata.Phone = updatedUser.phone;
    }
  
    // If no fields have been changed, return without making an API call
    if (Object.keys(formdata).length === 0) {
      toast.success("updated..");
      setIsEditing(false);
      return;
    }
  
    updateProfile(formdata, {
      onSuccess: () => {
        toast.success("updated");
        setUser(updatedUser);
        setIsEditing(false);
      },
      onError: (error) => {
        console.log(error);

        toast.error(error.response.data.message || "error while updating");
      },
    });
  };

  return (
    <div className="p-4">
      {/* Back Button */}
      <button
        onClick={handleBack}
        className="mb-4 text-blue-500 flex items-center"
      >
        <FaArrowLeft className="mr-2" />
        Back
      </button>

      {/* Profile Section */}
      <div className="flex flex-col items-center mb-6">
        <div className="relative">
          {/* Rounded Profile Image */}
          <img
            src="/images/founder.jpg"
            alt="Profile"
            className="w-24 h-24 rounded-full shadow-md"
          />
          {/* Change Image Button */}
          <button className="absolute bottom-0 right-0 bg-blue-500 p-2 rounded-full text-white shadow-md">
            <FaUserEdit />
          </button>
        </div>
      </div>
      {/* Otlet Name */}
      <div className="flex items-center mb-4">
        <FaHome className="text-gray-500 mr-2" />
        {isEditing ? (
          <input
            type="text"
            value={updatedUser?.outlet_name}
            onChange={(e) =>
              setUpdatedUser({ ...updatedUser, outlet_name: e.target.value })
            }
            className="flex-grow border p-2 rounded-md"
          />
        ) : (
          <span className="flex-grow">{user?.outlet_name}</span>
        )}
      </div>
      <hr className="mb-4" />
      {/* User Info Fields */}
      <div className="w-full">
        {/* Name Field */}
        <div className="flex items-center mb-4">
          <MdAccountBox className="text-gray-500 mr-2" />
          {isEditing ? (
            <input
              type="text"
              value={updatedUser?.firstname}
              onChange={(e) =>
                setUpdatedUser({ ...updatedUser, firstname: e.target.value })
              }
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{user?.firstname}</span>
          )}
        </div>
        <hr className="mb-4" />

        {/* Last Name Field */}
        <div className="flex items-center mb-4">
          <MdAccountBox className="text-gray-500 mr-2" />
          {isEditing ? (
            <input
              type="text"
              value={updatedUser?.lastname}
              onChange={(e) =>
                setUpdatedUser({ ...updatedUser, lastname: e.target.value })
              }
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{user?.lastname}</span>
          )}
        </div>
        <hr className="mb-4" />

        {/* Phone Field */}
        <div className="flex items-center mb-4">
          <FaPhone className="text-gray-500 mr-2" />
          {isEditing ? (
            <input
              type="text"
              value={updatedUser?.phone}
              onChange={(e) =>
                setUpdatedUser({ ...updatedUser, phone: e.target.value })
              }
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{user?.phone}</span>
          )}
        </div>
        <hr className="mb-4" />

        {/* Email Field */}
        <div className="flex items-center mb-4">
          <FaEnvelope className="text-gray-500 mr-2" />
          {isEditing ? (
            <input
              type="email"
              value={updatedUser?.email}
              onChange={(e) =>
                setUpdatedUser({ ...updatedUser, email: e.target.value })
              }
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{user?.email}</span>
          )}
        </div>

        {/* Edit/Update Buttons */}
        <div className="flex justify-end">
          {isEditing ? (
            <button
              onClick={handleUpdate}
              className="text-green-500 flex justify-center items-center gap-2"
            >
              <FaArrowRight /> Update
            </button>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="text-blue-500 flex justify-center items-center gap-2"
            >
              <FaPencilAlt /> Edit
            </button>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default UserProfile;
