import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaShoppingCart, FaUser, FaTag, FaMoneyBillWave } from 'react-icons/fa';
import useGetDeliveryDetails from '../../../../hooks/delivery/getDeliveryDetails';


const ViewOrderDetails = () => {
    const { orderid } = useParams();
    const navigate = useNavigate();
    let OrderData=null;
    const {data,error}=useGetDeliveryDetails(orderid);
    OrderData = data?.data;
    console.log(OrderData);
    
 

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <div className="p-4">
            {/* Header with Back Button */}
            <div className="flex items-center mb-4">
                <button onClick={handleBack} className="flex items-center text-blue-500 hover:underline">
                    <FaArrowLeft className="mr-2" /> Back
                </button>
                <h5 className="text-lg font-semibold ml-4">Order Details</h5>
            </div>

            {OrderData&&
            <div className="border rounded-md p-4 bg-white shadow-md">
                <h6 className="font-semibold text-lg mb-2"><FaShoppingCart className="inline mr-1" /> Invoice #{OrderData._id}</h6>
                <p className="text-sm text-gray-500 mb-4">Order Date: {OrderData.date}</p>

                <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaUser className="inline mr-1" /> Customer:</h6>
                    <p className="text-sm">{OrderData.customer_id.name}</p>
                </div>

                {/* Items Ordered Section */}
                <h6 className="font-semibold text-md mb-2"><FaTag className="inline mr-1" /> Items Ordered:</h6>
                {OrderData?.items?.map((item, index) => (
                    <div key={index} className="border-b border-gray-200 py-2">
                        <div className="flex justify-between">
                            <span className="font-semibold">{item.item_id.name}</span>
                            <span>₹{item.price_at_delivery}</span>
                        </div>
                        <div className="flex justify-between text-sm text-gray-600">
                            <span>Size: {item.item_id.size}/{item.item_id.unit}</span>
                            <span>Quantity: {item.quantity}</span>
                        </div>
                        <div className="flex justify-between text-sm text-gray-600">
                            <span></span>
                            <span>Total: ₹{item.quantity * item.price_at_delivery}</span>
                        </div>
                    </div>
                ))}

                {/* Grand Total Section */}
                <div className="mt-4 flex justify-between font-semibold">
                    <span className="text-lg">Total Amount:</span>
                    <span className="text-lg">₹{OrderData.totalamount}</span>
                </div>

                <div className="mb-4 mt-6">
                    <h6 className="font-semibold text-md"><FaUser className="inline mr-1" /> Delivered By:</h6>
                    <p className="text-sm">{OrderData.admin_id ? "by Admin" : "Worker"}</p>
                </div>

                <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaMoneyBillWave className="inline mr-1" />Delivery Status:</h6>
                    <p className={`text-sm ${OrderData.deliverystatus === 'delivered' ? 'text-green-500' : OrderData.deliverystatus === 'pending' ? 'text-yellow-500' : 'text-red-500'}`}>
                        {OrderData.deliverystatus}
                    </p>
                </div>
                <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaMoneyBillWave className="inline mr-1" />Payment Status:</h6>
                    <p className={`text-sm ${OrderData.paymentstatus === 'paid' ? 'text-green-500' : OrderData.paymentstatus === 'unpaid' ? 'text-yellow-500' : 'text-red-500'}`}>
                        {OrderData.paymentstatus}
                    </p>
                </div>
                {/* <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaTag className="inline mr-1" /> Delivery Address:</h6>
                    <p className="text-sm">{OrderData.building}</p>
                </div> */}
            </div>}
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default ViewOrderDetails;
