const SelectBuilding = ({Building,setBuilding,buildings})=>{
  // console.log("building data",buildings);
  
    return (<><div className="mb-4">
        <label className="block font-semibold mb-1">
          Select Building
        </label>
        <select
          value={Building}
          onChange={(e) => setBuilding(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value={null}>Select Customer</option>
          {buildings?.map((building)=>(
            <option value={building?._id}>{building?.name}</option>
          ))}
        </select>
      </div></>)
}

export default SelectBuilding;