import React, { useState } from "react";
import {
  FaArrowLeft,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaLock,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAddWorker from "../../../../hooks/worker/addworker";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

const NewWorker = () => {
  const navigate = useNavigate();
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const qryclient = useQueryClient();
  const onChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const { mutate: addworker, error } = useAddWorker();
  const handleSubmit = () => {
    addworker(formdata, {
      onSuccess: (data) => {
        toast.success(data.message || "Added Successfully");
        setFormData({ name: "", email: "", phone: "", password: "" });
        qryclient.invalidateQueries(["getWorkers"]);
        navigate(-1);
      },
      onError: (error) => {
        toast.error(error.response.data.message || "error while adding..");
      },
    });
  };
  return (
    <div className="">
      <div className="flex items-center mb-6">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="mr-4 text-gray-600 hover:text-gray-800"
        >
          <FaArrowLeft size={20} />
        </button>
        <h1 className="text-2xl font-semibold text-gray-700">New Worker</h1>
      </div>

      {/* Input Fields */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="space-y-4">
          {/* Name Field */}
          <div className="relative">
            <FaUser className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Name"
              name="name"
              onChange={onChange}
              value={formdata.name}
              className="w-full pl-10 pr-4 py-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400 transition"
            />
          </div>

          {/* Email Field */}
          <div className="relative">
            <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={onChange}
              value={formdata.email}
              className="w-full pl-10 pr-4 py-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400 transition"
            />
          </div>

          {/* Phone Field */}
          <div className="relative">
            <FaPhone className="absolute left-3 top-3 text-gray-400" />
            <input
              type="tel"
              placeholder="Phone"
              name="phone"
              onChange={onChange}
              value={formdata.phone}
              className="w-full pl-10 pr-4 py-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400 transition"
            />
          </div>

          {/* Password Field */}
          <div className="relative">
            <FaLock className="absolute left-3 top-3 text-gray-400" />
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={onChange}
              value={formdata.password}
              className="w-full pl-10 pr-4 py-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400 transition"
            />
          </div>
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="w-full mt-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 active:bg-blue-700 transition font-semibold"
        >
          Add Worker
        </button>
      </div>
    </div>
  );
};

export default NewWorker;
