import React, { useEffect, useState } from "react";
import { FaFilter, FaSearch, FaEye, FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useGetDeliveries from "../../../../hooks/delivery/getDeliveries";

const OrdersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [paymentfilter, setpaymentFilter] = useState("All");
  const [orders, setOrders] = useState(null);
  const { data, isLoading } = useGetDeliveries();
  useEffect(() => {
    setOrders(data?.deliveryrecords);
    console.log(data);
  }, [data, isLoading]);

  // Filter orders based on search term and filter type
  const filteredOrders =
    orders?.filter(
      (order) =>
        ((filter === "All" || order.deliverystatus === filter) &&  (paymentfilter === "All" || order.paymentstatus === paymentfilter)) &&
        order?.customer_id?.name.toLowerCase().includes(searchTerm?.toLowerCase())
    );

  const navigate = useNavigate();

  const handleViewOrderDetails = (id) => {
    navigate(`${id}`);
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-3">
        <h5 className="text-lg font-semibold">View Orders</h5>

        <button
          onClick={() => {
            navigate("newdelivery");
          }}
          className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-600"
        >
          <FaUserPlus />
          New Delivery
        </button>
      </div>

      {/* Filter Section */}
      <div className="sticky -top-4 bg-white -m-1 z-10 p-3 mb-4 border-b">
        {/* Search Bar Below the Tabs */}
        <div className="flex items-center gap-2 w-full mt-3">
          <FaSearch size={25} className=" text-gray-600" />
          <input
            type="text"
            placeholder="Search customers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border rounded-md w-full text-sm"
          />
          <div className="w-full flex gap-1">
            <select onChange={(e)=>setpaymentFilter(e.target.value)} className="w-full p-2 border rounded"><option>All</option><option value="paid">PAID</option><option value="unpaid">UNPAID</option></select>
            
          </div>
        </div>
        <div className="flex mt-2 gap-4 w-full">
          {/* Tab for Order Status Filter */}
          <button
            className={`flex-1 text-sm p-2 ${
              filter === "All"
                ? "border-b-2 border-blue-500 text-blue-500  font-semibold"
                : "text-gray-600"
            } hover:text-blue-500`}
            onClick={() => setFilter("All")}
          >
            All
          </button>
          <button
            className={`flex-1 text-sm p-2 ${
              filter === "delivered"
                ? "border-b-2 border-blue-500 text-blue-500  font-semibold"
                : "text-gray-600"
            } hover:text-blue-500`}
            onClick={() => setFilter("delivered")}
          >
            Delivered
          </button>
          <button
            className={`flex-1 text-sm p-2 ${
              filter === "pending"
                ? "border-b-2 border-blue-500 text-blue-500  font-semibold"
                : "text-gray-600"
            } hover:text-blue-500`}
            onClick={() => setFilter("pending")}
          >
            Pending
          </button>
        
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredOrders?.length > 0 ? (
          filteredOrders?.map((order) => (
            <div
              key={order?.id}
              className={`border relative bg-slate-50/50  rounded-lg flex shadow-md p-4 space-y-2 ${
                order?.paymentstatus === "paid" ? "border-none" : "border-none"
              }`}
            >
              {/* <div
                className={`absolute rounded-l-lg top-0 left-0 h-full w-2 ${
                  order?.deliverystatus === "delivered"
                    ? "bg-green-300"
                    : order?.deliverystatus === "pending"
                    ? "bg-red-300"
                    : "bg-red-200"
                }`}
              >
                <span className="text-xs font-semibold text-gray-800 translate-x-4 whitespace-nowrap">
                  {order?.deliverystatus.toUpperCase()}
                </span>
              </div> */}
              {/* Order Info */}
              <div className="w-full ">
                <div className="flex justify-between items-center">
                  <h3 className="font-semibold text-gray-800 text-sm">
                    Order ID:{" "}
                    <span className="text-blue-600">#{order?._id}</span>
                  </h3>
                  <p
                    className={`px-2 py-1 rounded-full text-xs font-semibold ${
                      order?.paymentstatus === "paid"
                        ? "bg-green-50 text-green-600"
                        : "bg-red-50 text-red-600"
                    }`}
                  >
                    {order?.paymentstatus.toUpperCase()}
                  </p>
                </div>

                {/* Customer and Delivery Info */}
                <div className="text-sm text-gray-600 space-y-1">
                  <p>
                    <i className="fas fa-user text-gray-400 mr-1"></i>
                    Customer: {order?.customer_id?.name}
                  </p>
                  <p>
                    <i className="fas fa-motorcycle text-gray-400 mr-1"></i>
                    Delivery Boy: {order.admin_id ? "by Admin" : "Worker"}
                  </p>
                 
                </div>

                {/* Order Details */}
                <div className="text-sm text-gray-600">
                  <p>
                    <i className="fas fa-box-open text-gray-400 mr-1"></i>
                    Items: {order?.itemsCount} | Quantity:{" "}
                    {order?.totalQuantity}
                  </p>
                  <p>
                    <i className="fas fa-rupee-sign text-gray-400 mr-1"></i>
                    Amount: ₹{order?.totalamount}
                  </p>
                  
                </div>

                {/* Status and Action */}
                <div className="flex justify-between items-center">
                  <p
                    className={`text-xs font-semibold ${
                      order?.deliverystatus === "delivered"
                      ? "text-green-600"
                      : order?.deliverystatus === "pending"
                      ? "text-yellow-600"
                      : "text-red-600"
                    }`}
                    >
                    <i className="fas fa-truck mr-1"></i>
                    {order?.deliverystatus.toUpperCase()}
                  </p>
                      <p
                    className={`text-xs font-semibold`}>{new Date(order?.date).toLocaleDateString('en-GB')}</p>
                  <button
                    className="text-blue-500 text-xs flex items-center gap-1 hover:underline"
                    onClick={() => handleViewOrderDetails(order?._id)}
                  >
                    <i className="fas fa-eye"></i> View Details
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500 py-4">
            No orders found.
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default OrdersPage;
