import { useState, useEffect } from 'react';

const DynamicLoaderFullScreen = () => {
  const images = [
    '/images/loader/1.gif',
    '/images/loader/2.gif',
    '/images/loader/3.gif',
    '/images/loader/4.gif',
    '/images/loader/5.gif',
  ];

  const texts = [
    'You’re doing great!',
    'Almost done!',
    'Just a few more seconds...',
    'It’ll be worth the wait!',
    'Thanks for your patience!',
  ];

  const [randomImage, setRandomImage] = useState('');
  const [randomText, setRandomText] = useState('');

  useEffect(() => {
    // Randomly select an image and text
    const randomImageIndex = Math.floor(Math.random() * images.length);
    const randomTextIndex = Math.floor(Math.random() * texts.length);

    setRandomImage(images[randomImageIndex]);
    setRandomText(texts[randomTextIndex]);
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="w-full h-screen flex flex-col absolute inset-0 backdrop-blur-sm justify-center items-center text-center">
      {randomImage && (
        <img
          src={randomImage}
          width="100"
          height="100"
          alt="ChaiBook Loader"
          className="animate-fade-in-up"
        />
      )}
      <span className="mt-4 text-lg font-medium">{randomText}</span>
    </div>
  );
};

export default DynamicLoaderFullScreen;
