import { useState } from "react";
import { CiShop } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { MdEmail, MdOutlinePassword } from "react-icons/md";
import useRegister from "../hooks/useRegister";
import toast from "react-hot-toast";
import Outlet_Signup_validation_Schema from "../validations/auth_validations/Outlet_Signup_validation";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    signup_type: "outlet",
    OutletName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConfirmPassword: "",
  });
  const handleFormChange = (e) => {
    console.log(e);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();
  const { mutate: Register, isLoading, error } = useRegister();
  const handleSubmit = async () => {

    const {error} = Outlet_Signup_validation_Schema.validate(formData, { abortEarly: false })
    if(error)
    {
      toast.error(error.details[0].message);
      return;
    }
    try {
      Register(formData, {
        onSuccess: (data) => {
          toast.success(data.message || "Registration successful!");
          setFormData({ signup_type: "outlet",
            FirstName: "",
            LastName: "",
            Email: "",
            Phone: "",
            Password: "",
            ConfirmPassword: "",
            OutletName: "",})
            navigate('/login')
          
        },
        onError: (err) => {
          console.log(err.response.data.message);
          toast.error(err.response.data.errors || err.response.data.message || "Registration failed");
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div class="font-[sans-serif]">
        <div
          style={{
            background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
            backdropFilter: "blur(10px)",
          }}
          class="grid lg:grid-cols-2 gap-4 max-lg:gap-12 bg-gradient-to-r from-blue-500 to-blue-700 px-8 py-12 h-[320px] rounded-b-3xl"
        >
          <div>
            <span className="text-xl font-extrabold text-white whitespace-nowrap flex">
              <img src="/images/ani-ico.gif" width={30} height={28} />
              CHAI BOOK
            </span>

            <div class="max-w-lg mt-16 max-lg:hidden">
              <h3 class="text-3xl font-bold text-white">Sign in</h3>
              <p class="text-sm mt-4 text-white">Sign Up Up Your Bussiness </p>
            </div>
          </div>
          <div class="bg-white shadow-lg rounded-xl sm:px-6 px-4 py-8 max-w-xl   w-full h-max shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] max-lg:mx-auto">
            <div>
              <div class="mb-2">
                <h3 class="text-3xl font-extrabold text-gray-800">Register</h3>
              </div>

              <br />
              <div class="container p-2">
                <form class="space-y-4">
                  <div class="w-full relative">
                    <label class="text-gray-800 text-sm mb-2 block">
                      Outlet Name
                    </label>
                    <div class="relative">
                      <input
                        type="text"
                        onChange={handleFormChange}
                        value={formData.OutletName}
                        name="OutletName"
                        required
                        class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                        placeholder="Enter Outlet Name"
                      />
                      <CiShop class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row gap-4">
                    <div class="w-full relative">
                      <label class="text-gray-800 text-sm mb-2 block">
                        First Name
                      </label>
                      <div class="relative">
                        <input
                          type="text"
                          onChange={handleFormChange}
                          value={formData.FirstName}
                          name="FirstName"
                          required
                          class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                          placeholder="Enter First Name"
                        />
                        <FaRegUser class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                      </div>
                    </div>
                    <div class="w-full relative">
                      <label class="text-gray-800 text-sm mb-2 block">
                        Last Name
                      </label>
                      <div class="relative">
                        <input
                          type="text"
                          onChange={handleFormChange}
                          value={formData.LastName}
                          name="LastName"
                          required
                          class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                          placeholder="Enter Last Name"
                        />
                        <FaRegUser class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col md:flex-row gap-4">
                    <div class="w-full relative">
                      <label class="text-gray-800 text-sm mb-2 block">
                        Email
                      </label>
                      <div class="relative">
                        <input
                          type="email"
                          onChange={handleFormChange}
                          value={formData.Email}
                          name="Email"
                          required
                          class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                          placeholder="Enter Email"
                        />
                        <MdEmail class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                      </div>
                    </div>
                    <div class="w-full relative">
                      <label class="text-gray-800 text-sm mb-2 block">
                        Phone
                      </label>
                      <div class="relative">
                        <input
                          type="tel"
                          required
                          onChange={handleFormChange}
                          value={formData.Phone}
                          name="Phone"
                          class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                          placeholder="Enter Phone"
                        />
                        <IoMdCall class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col md:flex-row gap-4">
                    <div class="w-full relative">
                      <label class="text-gray-800 text-sm mb-2 block">
                        Password
                      </label>
                      <div class="relative">
                        <input
                          type="password"
                          required
                          onChange={handleFormChange}
                          value={formData.Password}
                          name="Password"
                          class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                          placeholder="Enter Password"
                        />
                        <MdOutlinePassword class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                      </div>
                    </div>
                    <div class="w-full relative">
                      <label class="text-gray-800 text-sm mb-2 block">
                        Confirm Password
                      </label>
                      <div class="relative">
                        <input
                          type="password"
                          required
                          onChange={handleFormChange}
                          value={formData.ConfirmPassword}
                          name="ConfirmPassword"
                          class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-none focus:border-blue-600 pr-12"
                          placeholder="Confirm Password"
                        />
                        <MdOutlinePassword class="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-colors duration-300" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* ---------------------------endting inputs---------------------------- */}
              <div class="mt-8">
                <button
                  type="button"
                  onClick={handleSubmit}
                  class="w-full shadow-xl py-3 px-6 text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                >
                  Submit
                </button>
              </div>
              <div className="flex mt-4">
                <button
                  type="button"
                  class="p-2 w-full  whitespace-nowrap text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    class="inline mr-2  "
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#fbbd00"
                      d="M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z"
                      data-original="#fbbd00"
                    />
                    <path
                      fill="#0f9d58"
                      d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z"
                      data-original="#0f9d58"
                    />
                    <path
                      fill="#31aa52"
                      d="m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z"
                      data-original="#31aa52"
                    />
                    <path
                      fill="#3c79e6"
                      d="M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z"
                      data-original="#3c79e6"
                    />
                    <path
                      fill="#cf2d48"
                      d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z"
                      data-original="#cf2d48"
                    />
                    <path
                      fill="#eb4132"
                      d="M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z"
                      data-original="#eb4132"
                    />
                  </svg>
                  Register With Google
                </button>
              </div>
              <p class="text-sm mt-6 text-center text-gray-800">
                Already have an account{" "}
                <Link to="/login"
                  
                  class="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
