import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import FooterMenu from './components/Footermenu';
import Header from './components/Header';
import ProtectedRoute from '../ProtectedRoute';

const Dashboard = () => {

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
     

      {/* Main Content */}
      
      <main className="flex-grow p-4 overflow-y-scroll no-scrollbar">
        <Outlet />
      </main>

      {/* Footer */}
      <FooterMenu/>
    </div>
  );
};

export default Dashboard;
