import React, { useState } from "react";
import { FaEdit, FaUser, FaPhone, FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

const CustomerCard = ({ customer }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: customer?.name || "",
    address: customer?.address || "",
    email: customer?.contact?.email || "",
    phone: customer?.contact?.phone || "",
  });

  const handleEditClick = () => {
    setIsEditing((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    // Save data logic (e.g., API call)
    console.log("Saved Data:", formData);
    setIsEditing(false);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 flex flex-col items-center gap-2 mb-4 relative">
      {/* Edit Button */}
      <button
        onClick={handleEditClick}
        className="text-blue-600 hover:bg-blue-50 p-2 rounded-lg flex items-center fixed absolute top-2 right-2"
      >
        <FaEdit /> {isEditing ? "Cancel" : "Edit"}
      </button>

      {/* Profile Icon */}
      <div className="bg-blue-100 rounded-full">
      <img
             src={customer?.image || '/images/customer.png'}
            alt="Worker"
            className="w-14 h-14 rounded-full object-cover"
          />
      </div>

      {/* User Info */}
      <div className="text-center w-full">
        <div className="flex flex-col gap-2">
          {/* Name */}
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="text-center bg-gray-100 border border-gray-300 rounded-lg px-2 py-1 text-sm w-full"
              placeholder="Enter name"
            />
          ) : (
            <h3 className="font-semibold text-lg text-gray-800">
              {customer?.name || "Name Not Available"}
            </h3>
          )}

          {/* Address */}
          {isEditing ? (
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="text-center bg-gray-100 border border-gray-300 rounded-lg px-2 py-1 text-sm w-full"
              placeholder="Enter address"
            />
          ) : (
            <p className="text-sm text-gray-500">
              {customer?.address || "Address Not Available"}
            </p>
          )}

          {/* Email */}
          {isEditing && (
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="text-center bg-gray-100 border border-gray-300 rounded-lg px-2 py-1 text-sm w-full"
              placeholder="Enter email"
            />
          ) }

          {/* Phone */}
          {isEditing && (
            <input
              type="tel" 
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="text-center bg-gray-100 border border-gray-300 rounded-lg px-2 py-1 text-sm w-full"
              placeholder="Enter phone number"
            />
          ) }
        </div>
      </div>

      {/* Action Buttons */}
      {!isEditing&&<div className="flex gap-2 mt-2">
        <a
          href={`mailto:${formData.email}`}
          className="text-green-600 hover:bg-green-50 p-2 rounded-lg flex items-center gap-1"
        >
          <MdOutlineMailOutline />
          Email
        </a>
        <a
          href={`tel:${formData.phone}`}
          className="text-green-600 hover:bg-green-50 p-2 rounded-lg flex items-center gap-1"
        >
          <FaPhone /> Call
        </a>
        <a
          href={`https://wa.me/${formData.phone}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-600 hover:bg-green-50 p-2 rounded-lg flex items-center gap-1"
        >
          <FaWhatsapp /> Chat
        </a>
      </div>}

      {/* Save Button */}
      {isEditing && (
        <button
          onClick={handleSave}
          className="mt-3 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          Save
        </button>
      )}
    </div>
  );
};

export default CustomerCard;
