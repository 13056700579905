import { useMutation } from "@tanstack/react-query"
import axiosInstance from "../../utils/axiosInstance"

const useAddNewDelivery=()=>{
    return useMutation({
        mutationFn:async({data,uid})=>{
            const response = await axiosInstance.post(`/outlet/delivery/adddelivery/${uid}`,data);
            if(!response.data)
            {
                throw new Error("faild to add new delivery..")
            }
            return response.data;
        }
    })
}
export default useAddNewDelivery;