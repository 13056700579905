import React from 'react';
import { FaPrint, FaDownload, FaEnvelope } from 'react-icons/fa';

const Invoice = () => {
  const invoiceData = {
    invoiceNumber: 'INV-001',
    date: '2023-05-15',
    dueDate: '2023-06-15',
    company: {
      name: 'TechCorp Solutions',
      logo: 'https://via.placeholder.com/150x50',
      address: '123 Tech Street, Silicon Valley, CA 94000',
      phone: '+1 (555) 123-4567',
      email: 'billing@techcorp.com',
    },
    client: {
      name: 'John Doe',
      company: 'Client Enterprises',
      address: '456 Client Avenue, New York, NY 10001',
      email: 'john@cliententerprises.com',
    },
    items: [
      { description: 'Web Development Services', quantity: 1, rate: 5000, amount: 5000 },
      { description: 'UI/UX Design', quantity: 1, rate: 3000, amount: 3000 },
      { description: 'Server Maintenance (Monthly)', quantity: 12, rate: 500, amount: 6000 },
    ],
    subtotal: 14000,
    tax: 1400,
    total: 15400,
  };

  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 w-full">
        {/* <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div> */}
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="w-full mx-auto">
            <div className="flex items-center justify-between mb-8">
              <img src={invoiceData.company.logo} alt="Company Logo" className="h-12" />
              <h1 className="text-2xl font-semibold text-gray-700">Invoice</h1>
            </div>
            
            <div className="flex justify-between mb-8">
              <div>
                <h2 className="text-lg font-semibold text-gray-700">{invoiceData.company.name}</h2>
                <p className="text-sm text-gray-500">{invoiceData.company.address}</p>
                <p className="text-sm text-gray-500">{invoiceData.company.phone}</p>
                <p className="text-sm text-gray-500">{invoiceData.company.email}</p>
              </div>
              <div className="text-right">
                <p className="text-sm font-medium text-gray-700">Invoice Number: {invoiceData.invoiceNumber}</p>
                <p className="text-sm text-gray-500">Date: {invoiceData.date}</p>
                <p className="text-sm text-gray-500">Due Date: {invoiceData.dueDate}</p>
              </div>
            </div>
            
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-700 mb-2">Bill To:</h3>
              <p className="text-sm text-gray-600">{invoiceData.client.name}</p>
              <p className="text-sm text-gray-600">{invoiceData.client.company}</p>
              <p className="text-sm text-gray-600">{invoiceData.client.address}</p>
              <p className="text-sm text-gray-600">{invoiceData.client.email}</p>
            </div>
            
            <table className="w-full mb-8">
              <thead>
                <tr className="text-sm font-medium text-gray-700 border-b border-gray-200">
                  <th className="py-2 text-left">Description</th>
                  <th className="py-2 text-right">Qty</th>
                  <th className="py-2 text-right">Rate</th>
                  <th className="py-2 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.items.map((item, index) => (
                  <tr key={index} className="text-sm text-gray-600">
                    <td className="py-4 text-left">{item.description}</td>
                    <td className="py-4 text-right">{item.quantity}</td>
                    <td className="py-4 text-right">${item.rate.toFixed(2)}</td>
                    <td className="py-4 text-right">${item.amount.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            
            <div className="flex justify-end mb-8">
              <div className="text-right">
                <p className="text-sm text-gray-600 mb-1">Subtotal: ${invoiceData.subtotal.toFixed(2)}</p>
                <p className="text-sm text-gray-600 mb-1">Tax (10%): ${invoiceData.tax.toFixed(2)}</p>
                <p className="text-lg font-semibold text-gray-700">Total: ${invoiceData.total.toFixed(2)}</p>
              </div>
            </div>
            
            <div className="border-t pt-8 mb-8">
              <p className="text-sm text-gray-600 mb-1">Payment Terms: Net 30</p>
              <p className="text-sm text-gray-600">Please make checks payable to TechCorp Solutions</p>
            </div>
            
            <div className="flex justify-center space-x-4">
              <button className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200">
                <FaPrint className="mr-2" />
                Print
              </button>
              <button className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors duration-200">
                <FaDownload className="mr-2" />
                Download
              </button>
              <button className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition-colors duration-200">
                <FaEnvelope className="mr-2" />
                Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;